/* eslint-disable no-underscore-dangle */
import tippy from "tippy.js";
import favouriteApi from "../api/favourite";
import { on } from "../utils/listeners";

const setTippyMessage = (favourites, target = null, showTippy = true) => {
  favourites.forEach(favourite => {
    const tippyInstance = favourite._tippy;
    const activeFlag = favourite.className.includes("active");
    if (activeFlag) {
      tippyInstance.setContent(window.favouritesMessages.remove);
    } else {
      tippyInstance.setContent(window.favouritesMessages.add);
    }
  });
  if (target) {
    const isActive = target.className.includes("active");

    if (showTippy) {
      target._tippy.show();
    }
    if (isActive) {
      const prefixedAnalyticsId = target.dataset.analyticsId.includes("-")
        ? target.dataset.analyticsId
        : `PRODUCT-${target.dataset.analyticsId}`;

      analytics.track("Product Added to Wishlist", {
        analytics_id: prefixedAnalyticsId,
        sku: target.dataset.analyticsId
      });
      window.ttq?.serverTrack("AddToWishlist", {
        contents: [
          {
            content_id: String(target.dataset.analyticsId),
            content_type: target.dataset.type.toLowerCase(),
            price: parseFloat(target.dataset.price),
            quantity: 1
          }
        ]
      });
    }
  }
};

const addFavourite = ([type, id]) => {
  const favourites = document.querySelectorAll(
    `.js-favourite-container[data-type="${type}"][data-id="${id}"]:not(.clicked)`
  );
  if (favourites.length) {
    favourites.forEach(favourite => {
      favourite.classList.add("active");
    });
    setTippyMessage(favourites);
  }
};

const addFavouriteToWindow = (type, id) => {
  window.favouritedListings.push([type, parseInt(id, 10)]);
  window.dispatchEvent(new CustomEvent("addedFavourite"));
};

const removeFavouriteFromWindow = (type, id) => {
  const index = window.favouritedListings.findIndex(favourite => {
    return favourite[0] === type && favourite[1] === parseInt(id, 10);
  });

  if (index > -1) {
    window.favouritedListings.splice(index, 1);
  }

  window.dispatchEvent(new CustomEvent("removedFavourite"));
};

const highlightFavourites = (favourites, added) => {
  favourites.forEach(favourite => {
    favourite.classList.add("clicked");
    if (added) {
      favourite.classList.add("active");
    } else {
      favourite.classList.remove("active");
    }
  });
};

document.addEventListener("DOMContentLoaded", () => window.addFavourites());

window.addFavourites = () => {
  tippy(".js-favourite-container", {
    theme: "dark",
    content: "Add to favourites",
    arrow: true,
    touch: false,
    zIndex: 1000002
  });
  if (window.favouritedListings) {
    window.favouritedListings.forEach(favourite => addFavourite(favourite));
  }
};

window.resetFavourites = (type, id) => {
  const favourites = document.querySelectorAll(".js-favourite-container");
  favourites.forEach(favourite => {
    favourite.classList.remove("active");
    favourite.classList.remove("clicked");
  });
  removeFavouriteFromWindow(type, id);
  window.addFavourites();
  setTippyMessage(favourites);
};

on("click", ".js-favourite-container", async (el, e) => {
  e.preventDefault();
  const { id, type } = el.dataset;
  const favourites = document.querySelectorAll(
    `.js-favourite-container[data-type="${type}"][data-id="${id}"]`
  );

  const { data } = await favouriteApi.toggle({ id, type });
  if (data.added) {
    addFavouriteToWindow(type, id);
  } else {
    removeFavouriteFromWindow(type, id);
  }
  highlightFavourites(favourites, data.added);
  const showTippy = !el.closest(".drawer");
  setTippyMessage(favourites, el, showTippy);
});
/* eslint-enable no-underscore-dangle */
