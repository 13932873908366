import Cookies from "js-cookie";
import { throttle } from "lodash-es";
import { ready } from "../utils/listeners";
import cartApi from "../api/cart";

class CartOverlay {
  constructor() {
    this.cartHasBeenFetched = false;
    this.navCartButton = document.querySelector(".js-nav-cart-button");
    this.dropdownOverlay = document.querySelector(".dropdown-overlay");
    this.mobileBasket = document.getElementById("mobile-basket");
    this.isMobile = window.matchMedia("(max-width: 992px").matches;
  }

  async fetchCart() {
    if (!this.cartHasBeenFetched) {
      const { data } = await cartApi.showMiniCart();
      document.querySelector("#overlay-subtotal").innerHTML =
        data.totals.subtotal.formatted;
      this.cartHasBeenFetched = true;
    }
  }

  get hasItemsInCart() {
    return this.navCartButton.dataset.itemCount > 0;
  }

  async showOverlay() {
    if (this.isMobile) {
      await this.fetchCart();
      CartOverlay.setCookie();
    }
    this.setOverlayStyles();
    this.dropdownOverlay.classList.remove("hidden");
  }

  hideOverlay() {
    this.dropdownOverlay.classList.add("hidden");
  }

  setOverlayStyles() {
    const buttonRect = this.isMobile
      ? this.mobileBasket.getBoundingClientRect()
      : this.navCartButton.getBoundingClientRect();

    if (this.isMobile) {
      this.dropdownOverlay.style.top = `${buttonRect.bottom - 13}px`;
    } else {
      this.dropdownOverlay.style.top = `${buttonRect.bottom}px`;
      this.dropdownOverlay.style.right = `${window.innerWidth - buttonRect.right}px`;
    }
  }

  static setCookie() {
    Cookies.set("seenBasketOverlay", true, {
      expires: 7
    });
  }

  async handleMouseEnter() {
    if (this.hasItemsInCart) {
      await this.fetchCart();
      this.showOverlay();
    }
  }

  get hasFewerThanFiveCartItems() {
    return this.navCartButton.dataset.itemCount < 5;
  }

  async handleClick(e) {
    if (this.hasFewerThanFiveCartItems) {
      e.preventDefault();
      const module = await import("./miniCart");
      module.default();
    }
  }

  setupListeners() {
    this.navCartButton.addEventListener(
      "mouseenter",
      this.handleMouseEnter.bind(this)
    );

    this.navCartButton.addEventListener("click", this.handleClick.bind(this));

    this.dropdownOverlay.addEventListener(
      "mouseleave",
      this.hideOverlay.bind(this)
    );

    window.addEventListener(
      "scroll",
      throttle(this.hideOverlay.bind(this), 100)
    );

    if (this.isMobile) {
      window.addEventListener("click", this.hideOverlay.bind(this));
    }
  }

  init() {
    this.setupListeners();
    if (
      this.isMobile &&
      !Cookies.get("seenBasketOverlay") &&
      this.hasItemsInCart
    ) {
      this.showOverlay();
    }
  }
}

const cartOverlay = new CartOverlay();

ready(() => {
  if (document.querySelector(".js-nav-cart-button")) {
    cartOverlay.init();
    const basketString = document.querySelector(".js-basket-string");
    const overlayCartItemsString = document.querySelector(
      ".js-overlay-cart-items-count"
    );
    const overlaySubtotalString = document.querySelector(
      ".js-overlay-subtotal"
    );

    window.addEventListener("updateBasketString", e => {
      overlaySubtotalString.innerHTML = e.detail.subtotal;
      overlayCartItemsString.innerHTML = e.detail.cart_items_count;
      basketString.innerHTML = e.detail.basket_string;
    });
  }
});
