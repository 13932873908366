import { uniqWith, isEqual, differenceWith, groupBy } from "lodash-es";
import { on, ready } from "../utils/listeners";

on("click", ".js-mega-nav-item", el => {
  analytics.track("Clicked Mega Nav", {
    name: el.dataset.name,
    number: el.dataset.number,
    situation: el.dataset.situation
  });
});

on("click", ".js-open-menu", () => {
  analytics.track("Opened Menu");
});

on("click", ".js-nav-group-item", el => {
  analytics.track("Clicked Nav Group Item", {
    megaNavItemName: el.dataset.megaNavItemName,
    navGroupName: el.dataset.navGroupName,
    navGroupPosition: el.dataset.navGroupPosition,
    navGroupItemName: el.dataset.navGroupItemName,
    navGroupItemPosition: el.dataset.navGroupItemPosition
  });
});

on("click", ".js-book-builder-link", async el => {
  await analytics.track("Book Builder CTA Clicked on PLP", {
    category: el.dataset.categoryName
  });
  window.location = el.dataset.bookBuilderPath;
});

on("click", ".js-collection-drawer-link", () => {
  analytics.track("Clicked View the Full Collection on PLP");
});

on("click", ".js-collection-modal-listing .product", el => {
  analytics.track("Clicked Product in PLP Collection Modal", {
    analytics_id: el.dataset.id
  });
});

on("click", ".js-collection-drawer-favourites-cta", () => {
  analytics.track("Add All to Favourites (Collection Modal)");
});

on("click", ".js-collection-drawer-close-button", () => {
  analytics.track("Closed PLP Collection Modal");
});

on("click", ".js-comparison-link", el => {
  analytics.track("Clicked Size Guide", {
    productId: el.dataset.productId
  });
});

on("click", "[data-event]", el => {
  const { event, ...props } = el.dataset;

  // Take all data attributes, filter out ones that don't start with 'event',
  // remove the event prefix and lowercase the first letter.
  // IN: data-event-country="UK" data-event-category-name="Notebooks"
  // OUT: { country: "UK", categoryName: "Notebooks" }
  const filteredProps = Object.keys(props).reduce((acc, key) => {
    if (key.startsWith("event")) {
      const newKey =
        key.replace("event", "").charAt(0).toLowerCase() + key.slice(6);
      acc[newKey] = props[key];
    }
    return acc;
  }, {});
  analytics.track(event, filteredProps);
});

on("click", ".product", (p, e) => {
  if (!["IMG", "A"].includes(e.target.tagName)) {
    return;
  }

  const impressionList = p.closest(".js-impression-list");

  const click = {
    productId: p.dataset.id,
    analyticsId: p.dataset.id
  };

  if (impressionList) {
    Object.assign(click, impressionList.dataset);

    const position = [...impressionList.querySelectorAll(".product")].indexOf(
      p
    );

    if (position > -1) click.position = position + 1;
  }

  Object.keys(click).forEach(k => {
    if (k.startsWith("v")) delete click[k];
  });

  if (!click.analyticsId) {
    analytics.track("Clicked Product - Undefined Analytics ID", {
      classList: e.target.classList,
      tagName: e.target.tagName,
      dataSet: p.dataset
    });
  } else {
    analytics.track("Clicked Product", click);
  }
});

// Set up an intersection observer which reports product listing impressions to
// our analytics services. This needs to be rerun each time additional product cards
// have been added to the page, because of the way IntersectionObservers work

const viewedProducts = [];
let previouslyTrackedProducts = [];

const options = {
  root: null,
  rootMargin: "0px",
  threshold: [0.0, 0.75]
};

const callback = entries => {
  entries.forEach(entry => {
    const product = entry.target;

    if (entry.isIntersecting) {
      if (entry.intersectionRatio > 0.75) {
        product.dataset.lastViewStarted = entry.time;
        product.dataset.lastViewEnded = undefined;
      } else if (product.dataset.lastViewStarted) {
        product.dataset.lastViewEnded = entry.time;
      }
    }
  });
};

const alreadyTracked = product =>
  viewedProducts.some(p => p.id === product.dataset.id);

const observer = new IntersectionObserver(callback, options);

const handleRefreshInterval = () => {
  const currentTime = performance.now();

  document.querySelectorAll(".product").forEach(p => {
    const { lastViewStarted, lastViewEnded } = p.dataset;
    if (!lastViewStarted) return;
    if (alreadyTracked(p)) return;

    if (currentTime - parseFloat(lastViewStarted) > 150) {
      const ended = parseFloat(lastViewEnded) || currentTime;
      if (ended - parseFloat(lastViewStarted) > 150) {
        const impressionList = p.closest(".js-impression-list");

        const impression = { id: p.dataset.id };

        if (impressionList) {
          Object.assign(impression, impressionList.dataset);

          const position = [
            ...impressionList.querySelectorAll(".product")
          ].indexOf(p);

          if (position > -1) impression.position = position + 1;
        }

        viewedProducts.push(impression);
      }
    }
  });
};

const handleTracking = () => {
  const difference = uniqWith(
    differenceWith(viewedProducts, previouslyTrackedProducts, (p1, p2) => {
      return p1.id === p2.id;
    }),
    isEqual
  );

  previouslyTrackedProducts = [...viewedProducts];

  if (difference.length) {
    const groups = groupBy(difference, i => [i.situation, i.term]);

    Object.keys(groups).forEach(key => {
      const impressions = groups[key];
      const products = impressions.map(i => [i.id, i.position]);

      const { id, position, ...other } = impressions[0];

      const track = {
        products,
        ...other
      };

      // Vue adds data-v-xxx to tags, which isn't something we want to track (these get converted to columns
      // in the warehouse). Just remove anything beginning with v-
      Object.keys(track).forEach(k => {
        if (k.startsWith("v")) delete track[k];
      });

      analytics.track("Product Impression", track);
    });
  }
};

const setupImpressionTracker = () => {
  const products = document.querySelectorAll(".product");
  products.forEach(p => observer.observe(p));
};

window.setInterval(handleRefreshInterval, 100);
window.setInterval(handleTracking, 1000);

ready(() => {
  setupImpressionTracker();
});

window.addEventListener("htmlChanged", () => {
  setupImpressionTracker();
});
